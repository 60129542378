import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import Panel from '../components/Panel/Panel';
import { CENTURY_PAGE_QUERY } from '../queries/CENTURY_PAGE_QUERY';
import Year from './Year';
import { range } from '../utils/utils';
import CenturyTemplate from '../templates/CenturyTemplate/CenturyTemplate';
import { useStore, STORE_ACTIONS } from '../hooks/useStore';
import { useLocation } from 'react-router';
import { utrechtService } from '../services/utrecht-service';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion/dist/framer-motion';

const CloseButtonWrapper = styled.div`
  display: none;
  position: sticky;
  top: 2rem;
  z-index: 9999;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    display: block;
  }

  a {
    position: absolute;
    right: 2rem;
    font-size: 2rem;
    background: var(--color-primary);
    color: white;
    display: block;
    padding: 1.5rem 2rem;
    text-decoration: none;
    font-weight: normal;

    &:hover {
      background: var(--color-primary-dark);
    }
  }
`;

const Century = () => {
  const { dispatch } = useStore();
  const { pathname } = useLocation();
  const [showCenturyContent, setShowCenturyContent] = useState(false);
  const century = utrechtService.getCenturyByPathname({ pathname });
  const centuryRange = utrechtService.getCenturyRangeByCentury({ century });
  const allYearsUid = range(Number(centuryRange?.[0]), Number(centuryRange?.[1]), 1).map(year => year.toString());
  const MotionLink = motion(Link);
  const isCenturyPage = pathname.startsWith('/eeuw');

  const { loading, error, data } = useQuery(CENTURY_PAGE_QUERY, {
    variables: { century, allYearsUid },
  });

  const years = data?.allYears?.edges;
  const sortedYears = utrechtService.getSortedYears({ years });

  useEffect(() => {
    if (years) {
      dispatch({ type: STORE_ACTIONS.SET_YEARS, payload: years });
      dispatch({ type: STORE_ACTIONS.SET_MENU_IS_OPEN, payload: false });
    }

    return () => {
      dispatch({ type: STORE_ACTIONS.SET_MENU_IS_OPEN, payload: false });
    };
  }, [years, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      setShowCenturyContent(isCenturyPage);
    }, 1000);
  }, [isCenturyPage, showCenturyContent]);

  if (loading) return null;
  if (error) return <p>Error :(</p>;

  return (
    <>
      <Panel isVisible={isCenturyPage}>
        {showCenturyContent && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ease: [0.2, 0.82, 0.165, 1], duration: 1 }}
          >
            <CloseButtonWrapper>
              <MotionLink
                initial={{ y: '-100%', opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ ease: [0.2, 0.82, 0.165, 1], duration: 1, delay: 2 }}
                to="/"
              >
                Terug naar het overzicht
              </MotionLink>
            </CloseButtonWrapper>
            <CenturyTemplate data={data.allCenturys.edges[0].node} century={century} />
            {sortedYears?.map(year => (
              <Year key={year.node._meta.uid} data={year.node} century={century} />
            ))}
          </motion.div>
        )}
      </Panel>
    </>
  );
};

export default Century;
