import styled from 'styled-components/macro';
import { motion } from 'framer-motion/dist/framer-motion';

export const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  height: 100vh;
  width: 100%;
  overflow: auto;
  background-color: var(--color-bg);
  box-shadow: 0px 0 50px 0 #00000045;
`;
