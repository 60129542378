import styled from 'styled-components/macro';
import { motion } from 'framer-motion/dist/framer-motion';

export const Wrapper = styled(motion.section)`
  --container-spacing: 9.6rem 3.6rem;
  position: relative;
  height: 100vh;
  padding: var(--container-spacing);

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;

  &:-webkit-scrollbar {
    display: none;
  }

  @media (${({ theme }) => theme.respondTo.tabletL}) {
    overflow: auto;
    display: flex;
    align-items: center;
    padding: 0;
    padding-left: 4rem;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      padding-left: 14rem;
    }

    .items {
      position: relative;
      flex-shrink: 0;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 14rem;
        right: 0;
        height: 2px;
        opacity: 0.5;
        border-top: 2px dashed black;
        z-index: -1;
      }
    }
  }
`;

export const Intro = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 80rem;

  @media (${({ theme }) => theme.respondTo.tabletL}) {
    width: 60vw;
    flex-shrink: 0;
  }

  &:before {
    position: absolute;
    top: -5rem;
    left: 0;
    width: 15rem;
    height: 1.1rem;
    background: var(--color-primary-dark);
    @media (${({ theme }) => theme.respondTo.desktop}) {
      content: '';
    }
  }

  h1 {
    font-size: var(--font-size-xl);
    font-family: var(--font-primary);

    @media (${({ theme }) => theme.respondTo.desktop}) {
      --font-size-xl: 6.4rem;
    }
  }

  > p {
    color: var(--color-gray);
    white-space: break-spaces;
  }
`;

export const IntroCta = styled.div`
  margin: 2.4rem 0 0;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin: 5rem 0 0 auto;
  }

  h2 {
    font-size: var(--font-size-md);
    color: var(--color-primary);
    font-weight: normal;
    font-family: var(--font-primary);
    margin: 0;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      --font-size-md: 3rem;
    }
  }

  p {
    font-weight: bold;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      text-align: right;
    }
  }
`;
