export const ROUTES = {
  HOME: {
    URL: '/',
    MENU_TITLE: 'Home',
  },
  STAFF: {
    URL: '/medewerkers',
    MENU_TITLE: 'Medewerkers',
  },
  ABOUT: {
    URL: '/over-project',
    MENU_TITLE: 'Over project',
  },
  PARTNERS: {
    URL: '/partners',
    MENU_TITLE: 'Partners',
  },
  CONTACT: {
    URL: '/contact',
    MENU_TITLE: 'Contact',
  },
};
