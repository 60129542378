import React from 'react';
import { motion } from 'framer-motion/dist/framer-motion';

const AnimatedDiv = ({ children, ...rest }) => {
  return (
    <motion.div
      initial={{ y: 200, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ ease: [0.2, 0.82, 0.165, 1], duration: 2, delay: 0.5 }}
      {...rest}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedDiv;
