import React from 'react';

import { Container, ImageContainer, Wrapper, Content } from './TemplateA.styles';
import LazyImage from '../../components/LazyImage/LazyImage';
import Quote from '../../components/Quote/Quote';
import { RichText } from 'prismic-reactjs';
import { htmlSerializer } from '../../utils/htmlSerializer';
import AnimatedDiv from '../../components/AnimatedDiv/AnimatedDiv';

const TemplateA = ({ data }) => {
  return (
    <Wrapper>
      <Content>
        <Container>
          <AnimatedDiv>
            {data.year?.[0]?.text && (
              <time dateTime={data.year?.[0]?.text}>
                <span>{data.year?.[0]?.text}</span>
              </time>
            )}

            <h1>{data.title?.[0]?.text}</h1>
          </AnimatedDiv>
          <AnimatedDiv>
            {data.subtitle?.[0]?.text && <Quote color="white">{data.subtitle?.[0]?.text}</Quote>}
          </AnimatedDiv>
          <AnimatedDiv>{data?.text && <RichText render={data?.text} htmlSerializer={htmlSerializer} />}</AnimatedDiv>
        </Container>
        <ImageContainer>
          {data.main_image?.url && (
            <div className="image-wrapper">
              <LazyImage alt="@TODO" height="1000" width="1000" src={data.main_image.url} />
            </div>
          )}
        </ImageContainer>
      </Content>
    </Wrapper>
  );
};

export default TemplateA;
