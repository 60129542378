import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  background: var(--color-brown);
`;

export const Content = styled.div`
  --container-spacing: var(--template-padding-top) 3.2rem var(--template-padding-bottom);

  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: var(--max-container-width);
  min-height: 100vh;
  margin: 0 auto;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    --container-spacing: var(--template-padding-top) 6.4rem var(--template-padding-bottom) var(--template-padding-left);

    flex-wrap: nowrap;
  }

  @media (${({ theme }) => theme.respondTo.desktopXL}) {
    --container-spacing: var(--template-padding-top) 11.2rem var(--template-padding-bottom) var(--template-padding-left);
  }

  time {
    font-size: var(--font-size-sm);

    span {
      display: block;
      margin-top: 8px;
      color: var(--color-primary-dark);
      font-size: var(--font-size-xxl);
      font-family: var(--font-secondary);
      line-height: 1;
    }
  }
`;

export const Container = styled.div`
  position: relative;
  padding: var(--container-spacing);

  @media (${({ theme }) => theme.respondTo.desktop}) {
    width: 50vw;
    min-height: 100vh;
  }
`;

export const ImageContainer = styled.div`
  position: relative;

  > * {
    width: 100%;
    height: 100%;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: -80px;
    width: 160px;
    height: 11px;
    background: var(--color-primary-dark);
    z-index: 1;
  }

  &::before {
    top: 48px;
  }

  &::after {
    top: auto;
    bottom: 48px;
  }

  @media (${({ theme }) => theme.respondTo.desktop}) {
    position: sticky;
    top: 0;
    width: 50vw;
    height: 100vh;
    max-height: none;

    .image-wrapper > * {
      height: 100vh;
    }
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;
