import React from 'react';
import { Wrapper } from './Hamburger.styles';

const Hamburger = ({ isOpen, handleClick }) => {
  return (
    <Wrapper className={`hamburger ${isOpen ? 'open' : ''}`} data-menu="2" onClick={handleClick}>
      <div className="icon"></div>
    </Wrapper>
  );
};

export default Hamburger;
