import React from 'react';
import { RichText } from 'prismic-reactjs';
import { Container, ImageContainer, Wrapper, Content } from './TemplateI.styles';
import LazyImage from '../../components/LazyImage/LazyImage';
import { htmlSerializer } from '../../utils/htmlSerializer';
import Quote from '../../components/Quote/Quote';
import AnimatedDiv from '../../components/AnimatedDiv/AnimatedDiv';
import { dateTimeService } from '../../services/date-time-service';

const TemplateI = ({ data }) => {
  const day = dateTimeService.getLocalDateTime({ dateTime: data.day });

  return (
    <Wrapper>
      <Content>
        {data?.year?.[0]?.text && (
          <time className="large" dateTime={data?.year?.[0]?.text}>
            {data?.year?.[0]?.text}
          </time>
        )}
        {day && <time>{day.format('D MMMM')}</time>}
        <Container>
          <AnimatedDiv>
            {data?.main_image.url && <LazyImage alt="@TODO" height="322" width="500" src={data?.main_image.url} />}
          </AnimatedDiv>
          <AnimatedDiv>
            <h1>{data?.title?.[0]?.text}</h1>
            {data?.text && <RichText render={data?.text} htmlSerializer={htmlSerializer} />}
          </AnimatedDiv>
        </Container>
        <ImageContainer>
          <AnimatedDiv transition={{ ease: [0.2, 0.82, 0.165, 1], duration: 2, delay: 0.75 }}>
            {data?.second_image?.url && <LazyImage alt="@TODO" height="322" width="500" src={data?.second_image.url} />}
            {data?.subtitle?.[0]?.text && <Quote center>{data?.subtitle?.[0]?.text}</Quote>}
          </AnimatedDiv>
        </ImageContainer>
      </Content>
    </Wrapper>
  );
};

export default TemplateI;
