import { useQuery } from '@apollo/client';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import Layout from '../layouts/layout';
import ContentTemplate from '../templates/ContentTemplate/ContentTemplate';
import { htmlSerializer } from '../utils/htmlSerializer';
import { CONTACT_PAGE_QUERY } from '../queries/CONTACT_PAGE_QUERY';
import Loader from '../components/Loader/Loader';

const Contact = () => {
  const { loading, error, data } = useQuery(CONTACT_PAGE_QUERY);

  if (error) return <p>Error :(</p>;

  const title = data?.allContacts.edges[0].node.title[0].text;
  const text = data?.allContacts?.edges[0].node.text;

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <ContentTemplate title={title}>
          {text && <RichText render={text} htmlSerializer={htmlSerializer} />}
        </ContentTemplate>
      )}
    </Layout>
  );
};

export default Contact;
