import { gql } from '@apollo/client';

export const STAFF_PAGE_QUERY = gql`
  query {
    allStaffs {
      edges {
        node {
          title
          staffmembers {
            name
            jobtitle
          }
        }
      }
    }
  }
`;
