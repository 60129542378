import React from 'react';
import { Container, ImageContainer, Wrapper, Content } from './TemplateD.styles';
import LazyImage from '../../components/LazyImage/LazyImage';
import Quote from '../../components/Quote/Quote';
import { RichText } from 'prismic-reactjs';
import { htmlSerializer } from '../../utils/htmlSerializer';
import AnimatedDiv from '../../components/AnimatedDiv/AnimatedDiv';

const TemplateD = ({ data, bg }) => {
  return (
    <Wrapper bg={bg}>
      <Content>
        <ImageContainer>
          <AnimatedDiv>
            {data.main_image.url && (
              <div className="image-wrapper">
                <LazyImage alt="@TODO" height="400" width="400" src={data.main_image.url} />
              </div>
            )}
            {data.subtitle?.[0]?.text && (
              <Quote border center>
                {data.subtitle?.[0]?.text}
              </Quote>
            )}
          </AnimatedDiv>
        </ImageContainer>
        <Container>
          <AnimatedDiv>
            {data.year?.[0]?.text && (
              <div className="date-wrapper">
                <time dateTime={data.year?.[0]?.text}>{data.year?.[0]?.text}</time>
              </div>
            )}

            <h1>{data.title?.[0]?.text}</h1>

            {data?.text && <RichText render={data?.text} htmlSerializer={htmlSerializer} />}
          </AnimatedDiv>
        </Container>
      </Content>
    </Wrapper>
  );
};

export default TemplateD;
