import React, { useEffect, useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';

import { useStore, STORE_ACTIONS } from '../../hooks/useStore';
import { Wrapper } from './Video.styles';
import MuteSVG from '../../static/icons/mute.svg';
import UnmuteSVG from '../../static/icons/unmute.svg';
import { useRef } from 'react';

const Video = () => {
  const ref = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [showPlayButton, setShowPlayButton] = useState(true);
  const { dispatch } = useStore();
  const isDesktop = window.innerWidth > 960;
  const removeVideo = () => dispatch({ type: STORE_ACTIONS.HAS_PLAYED_VIDEO });

  const handlePlayClick = () => {
    if (ref?.current?.player) {
      ref?.current?.player.play();
      setShowPlayButton(false);
    }
  };

  return (
    <Wrapper exit={{ opacity: 0, transition: { duration: 1 } }}>
      <Vimeo
        ref={ref}
        video={isDesktop ? 658558773 : 658580098}
        muted
        volume={isMuted ? 0 : 1}
        playsinline
        dnt
        controls={false}
        frameborder="0"
        onEnd={removeVideo}
      />
      {showPlayButton && (
        <div className="play-button">
          <button onClick={handlePlayClick} />
        </div>
      )}
      <div className="action-bar">
        <div className="action-bar-content">
          <p onClick={removeVideo}>Sla de introductievideo over</p>
          <button onClick={() => setIsMuted(!isMuted)}>
            <img src={isMuted ? MuteSVG : UnmuteSVG} alt="Toggle volume" />
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default Video;
