import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  --container-spacing: 9.6rem 3.6rem;

  position: relative;
  min-height: 100vh;
  max-height: -webkit-fill-available;
  background: white;
  padding: var(--container-spacing);

  @media (${({ theme }) => theme.respondTo.tabletL}) {
    --container-spacing: 6.4rem 3.2rem 6.4rem 8rem;
  }

  @media (${({ theme }) => theme.respondTo.desktop}) {
    --container-spacing: 12.8rem 4rem 12.8rem 22.4rem;
    --font-size-xl: 6.4rem;
  }
`;

export const Content = styled.div`
  max-width: var(--max-content-width);

  h1 {
    &::before {
      display: block;
      height: 11px;
      width: 150px;
      margin-bottom: 3.2rem;
      content: '';
      background: var(--color-primary);
    }

    @media (${({ theme }) => theme.respondTo.desktop}) {
      margin-bottom: 3.2rem;
    }
  }

  p {
    color: var(--color-gray);
  }

  .intro {
    max-width: 64rem;
    color: var(--body-text);
  }
`;

export const StaffList = styled.ul`
  @media (${({ theme }) => theme.respondTo.desktop}) {
    column-count: 2;
    column-gap: 2.4rem;
  }

  li {
    margin-bottom: 0.8rem;
    color: var(--color-gray);
  }
`;

export const GridLogo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3.2rem 2.4rem;
  margin: 6.4rem 0;

  img {
    max-width: ${props => (props.large ? '16rem' : '12rem')};
    max-height: ${props => (props.large ? '12rem' : '10rem')};
    object-fit: contain;
    filter: grayscale(1);
    padding-right: 2.4rem;
    &:not(:last-child) {
      border-right: solid #e9e9e9 2px;
    }
  }

  @supports not (display: grid) {
    img:not(:last-child) {
      margin-right: 3.2rem;
    }
  }
`;
