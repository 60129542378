import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isToday from 'dayjs/plugin/isToday';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import 'dayjs/locale/nl';

dayjs.extend(utc).extend(timezone).extend(isToday).extend(isSameOrAfter);

export const dateTimeService = {
  getNow() {
    const now = new Date();
    return dayjs.tz(now, 'Europe/Amsterdam');
  },
  isToday({ dateTime }) {
    return dayjs.tz(dateTime, 'Europe/Amsterdam').isToday();
  },
  getLocalDateTime({ dateTime }) {
    if (!dateTime) return null;
    return dayjs.tz(dateTime, 'Europe/Amsterdam');
  },
  getDateFromUnix({ unix }) {
    return dayjs(unix * 1000);
  },
};
