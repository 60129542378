import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (${({ theme }) => theme.respondTo.tabletL}) {
    display: flex;
    flex-direction: row;
    padding-left: 10rem;
    overflow: auto;
  }
`;

export const TimelineItem = styled.div`
  @media (${({ theme }) => theme.respondTo.tabletL}) {
    display: flex;
    justify-content: space-between;
    text-align: center;
    flex: 0 0 110rem;
    width: 110rem;
    flex-shrink: 0;
    overflow: hidden;

    &.small {
      display: none;
      justify-content: center;
      flex: 0 0 70rem;
      width: 70rem;

      @media (${({ theme }) => theme.respondTo.tabletL}) {
        display: flex;
      }

      .small-img {
        height: 15rem;
        width: auto;
      }
    }
  }

  .back {
    display: none;
    position: relative;
    align-items: center;
    background: var(--color-bg);
    margin-left: 7rem;
    padding-left: 2rem;
    transform: translateY(10px);
    cursor: pointer;

    @media (${({ theme }) => theme.respondTo.tabletL}) {
      display: flex;
    }

    h2 {
      font-family: var(--font-primary);
      font-weight: normal;
      font-size: 1.8rem;
      text-decoration: underline;
      text-underline-offset: 3px;
    }

    &:after {
      content: '';
      position: absolute;
      left: 100%;
      width: 50rem;
      height: 10px;
      top: calc(50% - 10px);
      background: var(--color-bg);
      transform: translateY(-50%);
    }
  }

  > * {
    position: relative;
    flex-shrink: 0;
    transition: transform 0.75s cubic-bezier(0.165, 0.84, 0.44, 1);

    /* small image */
    &:nth-child(1) {
      display: flex;
      align-items: center;

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        height: 100%;
        width: 1px;
        background: var(--color-gray);
      }
    }

    /* big image */
    &:nth-child(2):not(.back) {
      &:hover {
        transform: scale(1.075);
        .play-icon {
          transform: translateX(-50%) translateY(-1.5rem);
        }
      }
    }
  }

  .small-img {
    display: none;

    @media (${({ theme }) => theme.respondTo.tabletL}) {
      display: block;
      width: 20rem;
    }
  }

  .lazy-link {
    display: block;
    overflow: hidden;
    margin-bottom: 6.4rem;

    @media (${({ theme }) => theme.respondTo.tabletL}) {
      margin-bottom: 0;
    }
  }

  .big-img {
    flex-shrink: 0;

    @media (${({ theme }) => theme.respondTo.mobile}) {
      padding-bottom: 2.4rem;
      transform: scale(1.4);
    }

    @media (${({ theme }) => theme.respondTo.tabletL}) {
      width: 90rem;
      max-width: 100vh;
    }
  }

  .play-icon {
    position: absolute;
    left: 50%;
    top: calc(50% - 5rem);
    height: 17rem;
    transform: translateX(-50%);
    pointer-events: none;
    transition: transform 0.75s cubic-bezier(0.165, 0.84, 0.44, 1);

    @media (${({ theme }) => theme.respondTo.tabletL}) {
      height: 30rem;
    }
  }
`;

export const ScrollIndicator = styled.div`
  display: none;
  margin-right: 8rem;
  cursor: pointer;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    display: flex;
  }

  img {
    width: 13rem;
    height: auto;
    background: var(--color-gray-light);
  }
`;
