import { useQuery } from '@apollo/client';
import React from 'react';
import Layout from '../layouts/layout';
import ContentTemplate from '../templates/ContentTemplate/ContentTemplate';
import { GridLogo } from '../templates/ContentTemplate/ContentTemplate.styles';
import { PARTNERS_PAGE_QUERY } from '../queries/PARTNERS_PAGE_QUERY';
import Loader from '../components/Loader/Loader';

const Partners = () => {
  const { loading, error, data } = useQuery(PARTNERS_PAGE_QUERY);

  if (error) return <p>Error :(</p>;

  const title = data?.allPartnerss.edges[0].node.title[0].text;
  const text = data?.allPartnerss?.edges[0].node.text;
  const largeLogos = data?.allPartnerss?.edges[0].node.large_logos;
  const smallLogos = data?.allPartnerss?.edges[0].node.small_logos;

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <ContentTemplate title={title}>
          {text && <p className="intro">{text}</p>}
          {largeLogos && (
            <GridLogo large>
              {largeLogos.map(logo => (
                <img
                  key={logo.large_logo.url}
                  src={logo.large_logo.url}
                  alt={logo.large_logo.alt ? logo.large_logo.alt : ''}
                />
              ))}
            </GridLogo>
          )}

          {smallLogos && (
            <GridLogo>
              {smallLogos.map(logo => (
                <img
                  key={logo.small_logo.url}
                  src={logo.small_logo.url}
                  alt={logo.small_logo.alt ? logo.small_logo.alt : ''}
                />
              ))}
            </GridLogo>
          )}
        </ContentTemplate>
      )}
    </Layout>
  );
};

export default Partners;
