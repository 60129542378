import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  background: ${({ bg }) => (bg ? bg : 'var(--color-primary)')};
  h1,
  h3,
  p,
  time {
    color: ${({ text }) => (text ? text : 'white')};
  }
`;

export const Content = styled.div`
  --container-spacing: var(--template-padding-top) calc(var(--space-unit) * 4) calc(var(--space-unit) * 12);

  position: relative;
  min-height: 100vh;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    --container-spacing: 0 8rem 12.8rem var(--template-padding-left);
  }

  @media (${({ theme }) => theme.respondTo.desktopXL}) {
    --container-spacing: 0 11.2rem 12.8rem var(--template-padding-left);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  max-width: var(--max-container-width);
  padding: var(--container-spacing);
  margin: 0 auto;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: repeat(2, auto);
    grid-gap: 4rem 8rem;
    margin-top: -4rem;
    z-index: 1;

    > *:nth-child(1) {
      grid-area: 1 / 1 / 2 / 3;
    }
    > *:nth-child(2) {
      grid-area: 2 / 1 / 3 / 2;
    }
    > *:nth-child(3) {
      grid-area: 2 / 2 / 3 / 3;
    }
  }

  h1 {
    @media (${({ theme }) => theme.respondTo.desktop}) {
      font-size: 69px;
    }
  }

  .content {
    margin-bottom: 6.4rem;
  }

  blockquote p {
    color: var(--color-black);
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  padding-top: 4rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding-top: 0;
  }

  time {
    display: block;
    color: white;
    font-size: 84px;
    font-family: var(--font-secondary);
    line-height: 1.2;
    text-align: center;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      position: absolute;
      top: 7.2rem;
      right: 5%;
      font-size: 172px;
      line-height: 1;
      z-index: 1;
    }
  }

  img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
    aspect-ratio: 21/10;
  }
`;
