import React from 'react';
import { Wrapper } from './Panel.styles';

const Panel = ({ children, isVisible }) => {
  return (
    <Wrapper
      initial={{ y: '100%' }}
      animate={{ y: isVisible ? 0 : '100%' }}
      transition={{ ease: [0.2, 0.82, 0.165, 1], duration: 1 }}
    >
      {children}
    </Wrapper>
  );
};

export default Panel;
