import React, { createContext, useReducer } from 'react';

const initialState = {
  hasPlayedVideo: process.env.NODE_ENV === 'development',
  // hasPlayedVideo: false,
  years: null,
  activeCentury: null,
  menuIsOpen: false,
};

const store = createContext(initialState);
const { Provider } = store;

const STORE_ACTIONS = {
  HAS_PLAYED_VIDEO: 'HAS_PLAYED_VIDEO',
  SET_YEARS: 'SET_YEARS',
  SET_ACTIVE_CENTURY: 'SET_ACTIVE_CENTURY',
};

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case STORE_ACTIONS.HAS_PLAYED_VIDEO:
        return {
          ...state,
          hasPlayedVideo: true,
        };
      case STORE_ACTIONS.SET_YEARS:
        return {
          ...state,
          years: action.payload,
        };
      case STORE_ACTIONS.SET_ACTIVE_CENTURY:
        return {
          ...state,
          activeCentury: action.payload,
        };
      case STORE_ACTIONS.SET_MENU_IS_OPEN:
        return {
          ...state,
          menuIsOpen: action.payload,
        };
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, STORE_ACTIONS, StateProvider };
