import React from 'react';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import Layout from '../layouts/layout';
import { HOME_PAGE_QUERY } from '../queries/HOME_PAGE_QUERY';
import { useStore } from '../hooks/useStore';
import HomeTemplate from '../templates/HomeTemplate/HomeTemplate';
import Video from '../components/Video/Video';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';

const Home = () => {
  const { error, data } = useQuery(HOME_PAGE_QUERY);
  const { state } = useStore();

  if (error) return <p>Error :(</p>;

  const title = data?.allHomepages.edges[0].node.title[0].text;
  const text = data?.allHomepages.edges[0].node.text[0].text;
  const items = data?.allCenturys.edges;

  return (
    <Layout>
      <Helmet>
        <title>900 jaar Utrecht - Gekomen om te blijven</title>
        <meta name="description" content="900 jaar geschiedenis" />
      </Helmet>

      {data && <HomeTemplate title={title} text={text} items={items} />}
      <AnimatePresence>{!state.hasPlayedVideo && <Video />}</AnimatePresence>
    </Layout>
  );
};

export default Home;
