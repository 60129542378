import { m } from 'framer-motion/dist/framer-motion';
import styled from 'styled-components/macro';

export const Wrapper = styled(m.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: black;
  z-index: 1;

  iframe {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .play-button {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;

    &:after {
      position: absolute;
      content: '';
      top: 50%;
      left: calc(50% + 0.2rem);
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 25px solid black;
      pointer-events: none;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      width: 7.8rem;
      height: 7.8rem;
      margin: 0;
      padding: 0;
      appearance: none;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.5s cubic-bezier(0.2, 0.82, 0.165, 1);

      &:hover {
        width: 9rem;
        height: 9rem;
      }
    }
  }

  .action-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background: rgba(0, 0, 0, 0.4);
  }

  .action-bar-content {
    display: flex;
    align-items: center;
    color: white;
    font-size: var(--font-size-sm);
    font-family: var(--font-primary);
    padding: 0.5rem 1rem;
    justify-content: space-between;
    width: 100%;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      padding: 1.4rem 2rem;
      justify-content: end;
      width: auto;
    }

    > * {
      cursor: pointer;
    }

    button {
      appearance: none;
      background: transparent;
      border: none;
      margin: 0 0 0 1.5rem;
    }
  }
`;
