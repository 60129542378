import React from 'react';
import TemplateA from '../templates/TemplateA/TemplateA';
import TemplateB from '../templates/TemplateB/TemplateB';
import TemplateD from '../templates/TemplateD/TemplateD';
import TemplateF from '../templates/TemplateF/TemplateF';
import TemplateI from '../templates/TemplateI/TemplateI';
import TemplateY from '../templates/TemplateY/TemplateY';

const Year = ({ data }) => {
  const renderTemplate = template => {
    switch (template) {
      case 'Template A':
        return <TemplateA data={data} />;
      case 'Template B':
        return <TemplateB data={data} />;
      case 'Template C':
        return <TemplateD data={data} />;
      case 'Template G':
        return <TemplateD bg="#B2A89E" data={data} />;
      case 'Template D':
        return <TemplateF data={data} />;
      case 'Template E':
        return <TemplateI data={data} />;
      case 'Template F':
        return <TemplateY data={data} />;
      case 'Template H':
        return <TemplateF bg="#BA9D7B" text="black" data={data} />;
      default:
        break;
    }
  };

  return (
    <div data-template={data.template} id={`year-${data._meta?.uid}`}>
      {renderTemplate(data.template)}
    </div>
  );
};

export default Year;
