import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 30px;
  height: 30px;
  transition-duration: 0.5s;

  .icon {
    transition-duration: 0.5s;
    position: absolute;
    height: 4px;
    width: 30px;
    top: 30px;
    background-color: black;

    &:before {
      transition-duration: 0.5s;
      position: absolute;
      width: 30px;
      height: 4px;
      background-color: black;
      content: '';
      top: -10px;
    }

    &:after {
      transition-duration: 0.5s;
      position: absolute;
      width: 30px;
      height: 4px;
      background-color: black;
      content: '';
      top: 10px;
    }
  }

  &.open {
    .icon {
      transition-duration: 0.5s;
      background: transparent;

      &:before {
        transform: rotateZ(45deg) scaleX(1.25) translate(6.5px, 6.5px);
        background-color: white;
      }

      &:after {
        transform: rotateZ(-45deg) scaleX(1.25) translate(6px, -6px);
        background-color: white;
      }
    }
  }
  &:hover {
    cursor: pointer;
  }
`;
