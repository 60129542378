export const utrechtService = {
  getCenturyByPathname({ pathname }) {
    const century = pathname?.split('/eeuw/')?.[1]?.split('/')?.[0];
    return century;
  },
  getCenturyRangeByCentury({ century }) {
    const centuryRange = century?.split('-');
    return centuryRange;
  },
  getActiveYearByPathname({ pathname }) {
    const splittedPathname = pathname.split('/');
    if (splittedPathname.length !== 4) return null;
    return splittedPathname[3];
  },
  getSortedCenturies({ centuries }) {
    if (!centuries) return;

    return [...centuries].sort((a, b) => {
      return a?.node?.century_start - b?.node?.century_start;
    });
  },
  getSortedYears({ years }) {
    if (!years) return;

    return [...years].sort((a, b) => {
      return a?.node?.year?.[0]?.text - b?.node?.year?.[0]?.text;
    });
  },
  getArrayOfYear({ years }) {
    if (!years) return;

    return years.map(year => Number(year.node?.year?.[0]?.text));
  },
};
