import React from 'react';
import { Wrapper } from './Loader.styes';

const Loader = () => {
  return (
    <Wrapper>
      <h2>Laden...</h2>
    </Wrapper>
  );
};

export default Loader;
