import { createGlobalStyle } from 'styled-components';
import TimesBold from '../static/fonts/TimesLTStd-Bold.woff';
import TimesBold2 from '../static/fonts/TimesLTStd-Bold.woff2';
import TradeGothicBold from '../static/fonts/TradeGothicLTStd-Bold.woff';
import TradeGothicBold2 from '../static/fonts/TradeGothicLTStd-Bold.woff2';
import TradeGothic from '../static/fonts/TradeGothicLTStd.woff';
import TradeGothic2 from '../static/fonts/TradeGothicLTStd.woff2';

const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: "TimesBold";
  src: url(${TimesBold2}) format('woff2'), url(${TimesBold}) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TradeGothic";
  src: url(${TradeGothic2}) format('woff2'), url(${TradeGothic}) format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "TradeGothic";
  src: url(${TradeGothicBold2}) format('woff2'), url(${TradeGothicBold}) format('woff');
  font-weight: 500;
  font-style: normal;
}

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    font-size: 62.5%;
    overscroll-behavior-y: none;
    scroll-behavior: smooth;

    // Colors
    --color-white: #ffffff;
    --color-gray-light: #DEDBD9;
    --color-gray: #807C74;
    --color-gray-dark: #4E4B46;
    --color-black: #191713;
    --color-bg: var(--color-gray-light);
    --color-text: #191713;
    --color-text-dark: #000000;
    --color-text-light: #ffffff;
    --color-primary: #DE1C25;
    --color-primary-dark: #CF4427;
    --color-brown: #BA9D7B;
    --color-gold: #BE9864;

    // Fonts
    --font-primary: "TradeGothic", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-secondary: "TimesBold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-size-base: 1.6rem;
    --font-size-xs: 1.4rem;
    --font-size-sm: 1.5rem;
    --font-size-md: 2.3rem;
    --font-size-lg: 3rem;
    --font-size-xl: 4.2rem; // H1
    --font-size-xxl: 9.1rem;


    @media (${({ theme }) => theme.respondTo.desktop}) {
      --font-size-sm: 1.5rem;
      --font-size-md: 2.3rem;
      --font-size-lg: 4.8rem;
    }


    // Layouts
    --max-container-width: 1600px;
    --max-content-width: 1024px;
    --sidebar-width: 7.6rem;
    --template-padding-left: calc(var(--sidebar-width) + 6.4rem);
    --template-padding-top: 3.2rem;
    --template-padding-bottom: 9.6rem;

    @media (${({ theme }) => theme.respondTo.desktopXL}) {
      --template-padding-top: 7.2rem;
      --template-padding-bottom: 12.8rem;
    }

    @media (${({ theme }) => theme.respondTo.desktopXL}) {
      --template-padding-top: 8.8rem;
    }

    // Spacing unit
    --space-unit: 0.8rem; // 8px
  }

  main {
    padding: 0;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    position: relative;
    padding: 0;
    margin: 0;
    height: 100vh;
    max-height: -webkit-fill-available;
    width: 100%;
    line-height: 1.8;
    font-family: var(--font-primary);
    background-color: var(--color-bg);
    color: var(--color-text);
    font-size: var(--font-size-base);
    overscroll-behavior-y: none;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 1.6rem;
    line-height: 1.1;
    font-family: var(--font-secondary);
  }

  h1 {
    font-size: var(--font-size-xl);
  }

  p, ul {
    margin: 0;
    padding: 0;

    + p,
    + a,
    + h2,
    + h3,
    + h4,
    + ul {
      margin: 1.5rem 0;
    }
  }

  a {
    color: inherit;
    font-weight: 500;
    text-decoration: underline;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  blockquote {
    margin-left: 0;
    font-family: var(--font-secondary);
    font-style: italic;
    font-size: var(--font-size-md);
    line-height: 1.2;
  }

  img {
    display: block;
    max-width: 100%;
  }

  input {
    font-family: inherit;
  }

  #scroll {
    position: absolute;
    top: 0;
    left: -14rem;
    visibility: hidden;
    width: 1px;
    height: 1px;
    pointer-events: none;
  }
`;

export default GlobalStyle;
