import { gql } from '@apollo/client';

export const CONTACT_PAGE_QUERY = gql`
  query {
   allContacts {
      edges {
        node {
          title
          text
        }
      }
    }
	}
`;
