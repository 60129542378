import React from 'react';
import Layout from '../layouts/layout';
import ContentTemplate from '../templates/ContentTemplate/ContentTemplate';
import { StaffList } from '../templates/ContentTemplate/ContentTemplate.styles';
import { STAFF_PAGE_QUERY } from '../queries/STAFF_PAGE_QUERY';
import { useQuery } from '@apollo/client';
import Loader from '../components/Loader/Loader';

const Staff = () => {
  const { loading, error, data } = useQuery(STAFF_PAGE_QUERY);

  if (error) return <p>Error :(</p>;

  const title = data?.allStaffs.edges[0].node.title[0].text;
  const staffMembers = data?.allStaffs?.edges[0].node.staffmembers;

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <ContentTemplate title={title}>
          {staffMembers && (
            <StaffList>
              {staffMembers.map(staff => (
                <p key={staff?.name}>
                  {staff?.name} / {staff?.jobtitle}
                </p>
              ))}
            </StaffList>
          )}
        </ContentTemplate>
      )}
    </Layout>
  );
};

export default Staff;
