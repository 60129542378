import styled, { css } from "styled-components";

export const Blockquote = styled.blockquote`
  text-align: ${props => props.center ? 'center' : 'left'};

  p {
    color: ${props => props.color};
    font-size: ${props => props.size === 'large' ? '28px' : '23px'};
    line-height: ${props => props.size === 'large' ? '1.14' : '1.2'};
  }

  ${props => props.border && css`
    position: relative;
    margin: 0;

    &::before,
    &::after {
      content: '';
      margin: 0 auto;
      display: block;
      width: 160px;
      height: 11px;
      background: ${props => props.color === 'white' ? 'var(--color-black)' : 'var(--color-primary)'};
      z-index: 1;
    }

    p {
      padding: 2.4rem;

      @media (${({ theme }) => theme.respondTo.desktop}) {
        padding: ${props => props.size === 'large' ? '4rem' : '2.4rem'};
      }
    }
  `}
`;