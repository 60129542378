import styled from 'styled-components/macro';
import { m } from 'framer-motion/dist/framer-motion';
import { Link } from 'react-router-dom';

export const Wrapper = styled(m.nav)`
  display: flex;
  justify-content: ${({ isOpen }) => !isOpen && 'space-between;'};
  flex-direction: ${({ isOpen }) => isOpen && 'column'};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0.8rem 2rem 3.2rem;
  border-top: 5px solid var(--color-primary);
  border-bottom: ${({ isOpen }) => !isOpen && '1px solid black'};
  background: var(--color-gray-light);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0.9)};
  overflow: auto;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    min-width: var(--sidebar-width);
    min-height: 100%;
  }

  .hamburger {
    @media (${({ theme }) => theme.respondTo.mobile}) {
      position: absolute;
      right: 10px;
      top: -10px;
    }
  }

  .logo-icon {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 3.4rem;

    @media (${({ theme }) => theme.respondTo.mobile}) {
      display: none;
    }
  }

  @media (${({ theme }) => theme.respondTo.desktop}) {
    flex-direction: column;
    justify-content: unset;
    right: auto;
    height: 100vh;
    width: var(--sidebar-width);
    border: none;
    border-right: ${({ isOpen }) => !isOpen && '1px solid black'};
    border-left: ${({ isOpen }) => !isOpen && '5px solid var(--color-primary)'};
    background: transparent;
    padding: ${({ isOpen }) => (!isOpen ? '2rem 1.6rem 4rem' : '2rem 2.4rem 4rem')};
  }
`;

export const MenuContent = styled(m.div)`
  display: flex;
  flex-direction: column;

  h2 {
    margin: 4rem 0 2.4rem;
    color: ${({ isCentury }) => (!isCentury ? 'var(--color-text-dark)' : 'var(--color-gold)')};
    font-family: var(--font-primary);
  }

  .subtitle {
    margin-bottom: 1.6rem;
    font-size: 19px;
    line-height: 1;
    font-weight: 400;
    text-transform: uppercase;
    color: white;
  }
`;

export const CenturyList = styled(m.ul)`
  margin-bottom: 2.4rem;

  li {
    padding: 0.6rem 0 0;
    border-bottom: 1px solid ${({ isCentrury }) => (!isCentrury ? 'white' : 'var(--color-gold)')};
  }

  .century-link {
    display: flex;
    align-items: center;
    color: white;
    font-weight: 300;
    text-decoration: none;
    transition: transform 0.3s cubic-bezier(0.2, 0.82, 0.165, 1);

    &::before {
      content: '▶';
      position: absolute;
      left: -1.6rem;
      font-size: 8px;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s cubic-bezier(0.2, 0.82, 0.165, 1);
    }

    &:hover {
      transform: translateX(1.6rem);

      &::before {
        opacity: 1;
      }
    }
  }
`;

export const List = styled(m.ul)`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: auto;
  font-size: var(--font-size-md);
  height: auto;

  a {
    text-decoration: none;
    white-space: nowrap;
    font-weight: normal;
    color: white;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const MobileBackToHomeLink = styled(Link)`
  text-decoration: none;
  font-style: italic;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    display: none;
  }
`;
