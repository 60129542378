import React, { useEffect } from 'react';
import { LazyMotion, domAnimation } from 'framer-motion/dist/framer-motion';
import { Routes, Route } from 'react-router-dom';
import smoothScroll from 'smoothscroll-for-websites/SmoothScroll';
import smoothscrollPolyfill from 'smoothscroll-polyfill';
import { ROUTES } from './routes';
import Home from './pages/Home';
import Staff from './pages/Staff';
import About from './pages/About';
import Partners from './pages/Partners';
import Contact from './pages/Contact';
import GlobalStyle from './styling/GlobalStyle';

const App = () => {
  useEffect(() => {
    smoothScroll({
      animationTime: 600,
      stepSize: 120,
      accelerationDelta: 50,
      accelerationMax: 9,
      keyboardSupport: true,
      arrowScroll: 100,
      pulseAlgorithm: true,
      pulseScale: 5,
      pulseNormalize: 1,
      touchpadSupport: false,
    });

    // Polyfill scroll-behaviour: smooth
    smoothscrollPolyfill.polyfill();
  }, []);

  return (
    <main>
      <GlobalStyle />
      <LazyMotion features={domAnimation}>
        <Routes>
          <Route path={ROUTES.STAFF.URL} element={<Staff />} />
          <Route path={ROUTES.ABOUT.URL} element={<About />} />
          <Route path={ROUTES.PARTNERS.URL} element={<Partners />} />
          <Route path={ROUTES.CONTACT.URL} element={<Contact />} />
          <Route path="/eeuw/*" element={<Home />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </LazyMotion>
    </main>
  );
};

export default App;
