import { gql } from '@apollo/client';

export const PARTNERS_PAGE_QUERY = gql`
  query {
   allPartnerss {
      edges {
        node {
          title
          text
          large_logos {
            large_logo
          }
          small_logos {
            small_logo
          }
        }
      }
    }
	}
`;
