import React from 'react';
import LazyLoad from 'vanilla-lazyload';
import { Wrapper } from './LazyImage.styles';

// Only initialize it one time for the entire application
if (!document.lazyLoadInstance) {
  document.lazyLoadInstance = new LazyLoad({
    class_loading: 'loading',
    class_loaded: 'loaded',
    elements_selector: '.lazy',
  });
}

export class LazyImage extends React.Component {
  // Update lazyLoad after first rendering of every image
  componentDidMount() {
    document.lazyLoadInstance.update();
  }

  // Update lazyLoad after rerendering of every image
  componentDidUpdate() {
    document.lazyLoadInstance.update();
  }

  // Just render the image with data-src
  render() {
    const { alt, src, srcset, sizes, width, height, className } = this.props;
    return (
      <Wrapper>
        <img
          alt={alt}
          className={`${className ? className + ' lazy' : 'lazy'}`}
          data-src={src}
          data-srcset={srcset}
          data-sizes={sizes}
          width={width}
          height={height}
        />
      </Wrapper>
    );
  }
}

export default LazyImage;
