import { RichText } from 'prismic-reactjs';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import LazyImage from '../../components/LazyImage/LazyImage';
import { Wrapper, Content, ImageWrapper, ScrollIndicator, Highlights } from './CenturyTemplate.styles';
import { htmlSerializer } from '../../utils/htmlSerializer';
import MouseSVG from '../../static/icons/mouse.svg';
import { STORE_ACTIONS } from '../../store/store';
import { useStore } from '../../hooks/useStore';
import { useLocation } from 'react-router';
import { utrechtService } from '../../services/utrecht-service';
import { Link } from 'react-router-dom';
import AnimatedDiv from '../../components/AnimatedDiv/AnimatedDiv';

const CenturyTemplate = ({ data, century }) => {
  const { title, text, century_start, century_end, main_image, highlights, split_header_image } = data;
  const ref = useRef(null);
  const { state, dispatch } = useStore();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch({ type: STORE_ACTIONS.SET_ACTIVE_CENTURY, payload: { ...data, century } });
  }, [data, century, dispatch]);

  useEffect(() => {
    const activeYear = utrechtService.getActiveYearByPathname({ pathname });

    if (activeYear) {
      const yearDOMel = document.querySelector(`#year-${activeYear.toString()}`);
      yearDOMel.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [pathname]);

  const handleClickScrollIndicator = () => {
    const height = ref?.current?.getBoundingClientRect().height;
    const panelEl = ref?.current?.parentNode?.parentNode?.parentNode;

    if (panelEl) {
      panelEl.scrollTo({ top: height, left: 0, behavior: 'smooth' });
    }
  };

  return (
    <div>
      <Helmet>
        <title>
          {century_start.toString()} - {century_end.toString()}: {title[0].text} | 900 jaar Utrecht
        </title>
        <meta name="description" content={text} />
      </Helmet>

      <Wrapper ref={ref}>
        <ImageWrapper>
          <LazyImage className="small-img" src={split_header_image?.url || main_image.url} alt="@TODO" />
        </ImageWrapper>
        <Content>
          <h1>
            <AnimatedDiv>
              <span>
                {century_start.toString()} - {century_end.toString()}
              </span>
            </AnimatedDiv>
            <AnimatedDiv transition={{ ease: [0.2, 0.82, 0.165, 1], duration: 2, delay: 0.6 }}>
              {title[0].text}
            </AnimatedDiv>
          </h1>
          {text && (
            <AnimatedDiv transition={{ ease: [0.2, 0.82, 0.165, 1], duration: 2, delay: 0.7 }}>
              <div className="content">
                <RichText render={text} htmlSerializer={htmlSerializer} />
              </div>
            </AnimatedDiv>
          )}
          {highlights?.length > 0 && (
            <Highlights>
              <AnimatedDiv transition={{ ease: [0.2, 0.82, 0.165, 1], duration: 2, delay: 0.8 }}>
                <h2>Hoogtepunten</h2>
                {highlights.map(highlight => {
                  if (!highlight?.year) return;

                  return (
                    <Link
                      key={`highlight` + highlight.year?.title?.[0].text}
                      to={`/eeuw/${state?.activeCentury?.century}/${highlight.year._meta.uid}`}
                    >
                      <p>
                        {highlight.year?.title?.[0].text}, {highlight.year?.year?.[0].text}
                      </p>
                    </Link>
                  );
                })}
              </AnimatedDiv>
            </Highlights>
          )}
          <ScrollIndicator onClick={handleClickScrollIndicator}>
            <img src={MouseSVG} alt="" />
          </ScrollIndicator>
        </Content>
      </Wrapper>
    </div>
  );
};

export default CenturyTemplate;
