import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  --container-spacing: calc(var(--space-unit) * 4) calc(var(--space-unit) * 4) calc(var(--space-unit) * 12);

  position: relative;
  min-height: 100vh;
  background-color: var(--color-bg);

  @media (${({ theme }) => theme.respondTo.desktop}) {
    display: flex;
    align-items: center;
  }

  @media (${({ theme }) => theme.respondTo.desktopXL}) {
    --container-spacing: 8.8rem 11.2rem 12.8rem 0.8rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--color-black);
  color: var(--color-text-light);
  padding: var(--container-spacing);

  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding: 16.7rem 3.2rem var(--template-padding-bottom) 7.7rem;
    min-height: 100vh;
  }

  h1 span {
    display: block;
    color: var(--color-primary-dark);
  }

  .content {
    margin: 4.2rem 0 8.4rem;
  }

  h2 {
    color: var(--color-primary-dark);
  }
`;

export const Highlights = styled.div`
  a {
    text-decoration: none;
  }
  p {
    position: relative;

    &:before {
      content: '';
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 7px 10px 7px 0;
      border-color: transparent white transparent transparent;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      transform: scaleX(-1);
    }
  }
`;
export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 calc(50vw + 2rem);
  height: 75vh;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    position: sticky;
    top: 0;
    height: auto;
  }
`;

export const ScrollIndicator = styled.div`
  display: none;
  position: sticky;
  margin-top: auto;
  bottom: 2rem;
  align-items: center;
  justify-content: center;
  transform: translateX(-9.75rem);
  background-color: var(--color-primary);
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 50%;
  cursor: pointer;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    display: flex;
  }
`;
