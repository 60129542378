import { ApolloClient, InMemoryCache } from '@apollo/client';
import { PrismicLink } from 'apollo-link-prismic';

export const apolloClient = new ApolloClient({
  link: PrismicLink({
    uri: 'https://utrecht900.cdn.prismic.io/graphql',
    accessToken: process.env.REACT_APP_PRISMIC_API_TOKEN,
  }),
  cache: new InMemoryCache(),
});
