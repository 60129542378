import { motion } from 'framer-motion/dist/framer-motion';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import Timeline from '../../components/Timeline/Timeline';
import { useStore } from '../../hooks/useStore';
import { ROUTES } from '../../routes';
import { Wrapper, Intro, IntroCta } from './HomeTemplate.styles';

const HomeTemplate = ({ title, text, items }) => {
  const { state } = useStore();
  const isDesktop = window.innerWidth > 960;
  const ref = useRef(null);
  const location = useLocation();
  const isHome = location.pathname === ROUTES.HOME.URL;

  useEffect(() => {
    if (!isHome || !isDesktop) return;
    const transformScroll = e => {
      if (!e.deltaY) return;

      if (ref) {
        ref.current.scrollLeft += e.deltaY;
      }
    };

    const element = document.scrollingElement || document.documentElement;
    element.addEventListener('wheel', transformScroll);

    return () => element.removeEventListener('wheel', transformScroll);
  }, [ref, isHome]);

  return (
    <Wrapper ref={ref}>
      <Intro
        initial={isDesktop && { y: 200, opacity: 0 }}
        animate={state.hasPlayedVideo && { y: 0, opacity: 1 }}
        transition={{ ease: [0.2, 0.82, 0.165, 1], duration: 3, delay: 1 }}
      >
        <div id="scroll" />
        <h1>{title}</h1>
        <p>{text}</p>
        <IntroCta>
          <h2>In welke eeuw wil je starten?</h2>
          <p>{isDesktop ? 'Scroll om meer te zien' : 'Swipe naar beneden'}</p>
        </IntroCta>
      </Intro>

      <motion.div
        initial={isDesktop && { x: 600, opacity: 0 }}
        animate={state.hasPlayedVideo && { x: 0, opacity: 1 }}
        transition={{ ease: [0.2, 0.82, 0.165, 1], duration: 3, delay: 1.25 }}
        className="items"
      >
        {items && <Timeline items={items} />}
      </motion.div>
    </Wrapper>
  );
};

export default HomeTemplate;
