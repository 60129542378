import { AnimatePresence } from 'framer-motion/dist/framer-motion';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useStore, STORE_ACTIONS } from '../../hooks/useStore';
import { ROUTES } from '../../routes';
import { utrechtService } from '../../services/utrecht-service';
import { Wrapper, CenturyList, List, MenuContent, MobileBackToHomeLink } from './SidebarNavigation.styles';
import LogoSVG from '../../static/icons/logo.svg';
import Hamburger from '../Hamburger/Hamburger';
import { range, sliceIntoChunks } from '../../utils/utils';

const SidebarNavigation = ({ centuries }) => {
  const { state, dispatch } = useStore();
  const [isCentury, setIsCentury] = useState(false);
  const location = useLocation();
  const isDesktop = window.innerWidth > 960;
  const isOpen = state.menuIsOpen;

  // centuries & years
  const sortedCenturies = utrechtService.getSortedCenturies({ centuries });
  const firstYearOfThisCentury = state?.activeCentury?.century_start;

  const arrayOfYears = utrechtService.getArrayOfYear({ years: state.years });

  const yearsGrouped = [...Array(10)].map((_, i) => {
    return [firstYearOfThisCentury + i * 10, firstYearOfThisCentury + (i + 1) * 10];
  });

  const filteredYears = yearsGrouped
    .map(groupOfYears => {
      const yearsWithinRange = range(groupOfYears[0], groupOfYears[1], 1);
      const foundYears = arrayOfYears?.filter(r => yearsWithinRange.includes(r));
      if (foundYears?.length) {
        const sortedFoundYears = foundYears.sort();
        return [...groupOfYears, sortedFoundYears[0]];
      }
    })
    .filter(Boolean);

  useEffect(() => {
    const isCentury = location.pathname.startsWith('/eeuw');
    if (isCentury) {
      setTimeout(() => {
        setIsCentury(true);
      }, 1500);
    } else {
      setIsCentury(false);
    }
  }, [location]);

  useEffect(() => {
    const body = document.querySelector('body');
    isOpen ? (body.style.overflow = 'hidden') : (body.style.overflow = 'initial');

    return () => (document.querySelector('body').style.overflow = 'initial');
  }, [isOpen]);

  return (
    <Wrapper
      animate={{
        width: isDesktop ? (isOpen ? '27rem' : 'var(--sidebar-width)') : '100%',
        height: !isDesktop ? (isOpen ? '100%' : '5rem') : '100%',
        background: isOpen
          ? isCentury
            ? 'var(--color-black)'
            : 'var(--color-primary)'
          : isCentury && isDesktop
          ? 'rgba(255,255,255,0)'
          : 'var(--color-gray-light)',
        color: isOpen ? (isCentury ? 'var(--color-gold)' : 'white') : 'inherit',
      }}
      transition={{ ease: [0.2, 0.82, 0.165, 1], duration: 0.75 }}
      isOpen={isOpen}
    >
      {isCentury && !isOpen && <MobileBackToHomeLink to="/">Terug naar het overzicht</MobileBackToHomeLink>}
      <Hamburger
        isOpen={isOpen}
        handleClick={() => dispatch({ type: STORE_ACTIONS.SET_MENU_IS_OPEN, payload: !isOpen })}
      />
      {!isOpen && (
        <a href="https://www.gekomenomteblijven.nl/" target="_blank">
          <img className="logo-icon" src={LogoSVG} alt="" />
        </a>
      )}
      <AnimatePresence>
        {isOpen && (
          <MenuContent
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: [0.2, 0.82, 0.165, 1], duration: 0.3 }}
            isCentury={isCentury}
          >
            {centuries && (
              <div>
                <h2>Overzicht</h2>
                {isCentury && state.years && (
                  <>
                    <p className="subtitle">
                      Duik dieper <br /> in deze eeuw
                    </p>
                    <CenturyList>
                      {filteredYears?.map(year => (
                        <li key={year[0]}>
                          <Link to={`/eeuw/${state.activeCentury.century}/${year[2]}`} className="century-link">
                            {year[0]} - {year[1]}
                          </Link>
                        </li>
                      ))}
                    </CenturyList>
                  </>
                )}

                {!isCentury && (
                  <>
                    <p className="subtitle">
                      In welke eeuw <br /> wil je starten?
                    </p>
                    <CenturyList>
                      {sortedCenturies.map(({ node: century }) => (
                        <li key={century._meta.uid}>
                          <Link to={`/eeuw/${century._meta.uid}`} className="century-link" key={century._meta.uid}>
                            {century.century_start.toString()} t/m {century.century_end.toString()}
                          </Link>
                        </li>
                      ))}
                    </CenturyList>
                  </>
                )}
              </div>
            )}
            <List>
              <Link to={ROUTES.HOME.URL}>{ROUTES.HOME.MENU_TITLE}</Link>
              <Link to={ROUTES.ABOUT.URL}>{ROUTES.ABOUT.MENU_TITLE}</Link>
              <Link to={ROUTES.PARTNERS.URL}>{ROUTES.PARTNERS.MENU_TITLE}</Link>
              <Link to={ROUTES.STAFF.URL}>{ROUTES.STAFF.MENU_TITLE}</Link>
              <a href="http://gekomenomteblijven.nl/" target="_blank">
                Portretsite
              </a>
              <Link to={ROUTES.CONTACT.URL}>{ROUTES.CONTACT.MENU_TITLE}</Link>
            </List>
          </MenuContent>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

export default SidebarNavigation;
