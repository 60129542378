import { gql } from '@apollo/client';

export const SIDEBAR_QUERY = gql`
  query {
    allCenturys {
      edges {
        node {
          century_start
          century_end
          _meta {
            uid
          }
        }
      }
    }
  }
`;
