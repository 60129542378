import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { StateProvider } from './store/store';
import { createBrowserHistory } from 'history';
import { theme } from './styling/theme';
import App from './App';
import { apolloClient } from './lib/apollo';

const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <StateProvider>
            <BrowserRouter history={history}>
              <App />
            </BrowserRouter>
          </StateProvider>
        </ThemeProvider>
      </ApolloProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
