import { gql } from '@apollo/client';

export const HOME_PAGE_QUERY = gql`
  query {
    allHomepages {
      edges {
        node {
          title
          text
        }
      }
    }
    allCenturys {
      edges {
        node {
          ... on Century {
            century_start
            century_end
            title
            main_image
            before_image
            _meta {
              id
              uid
            }
            highlights {
              year {
                ... on Year {
                  title
                  year
                  _meta {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
