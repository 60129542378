import React from 'react';
import { Container, ImageContainer, Wrapper, Content } from './TemplateB.styles';
import LazyImage from '../../components/LazyImage/LazyImage';
import Quote from '../../components/Quote/Quote';
import { RichText } from 'prismic-reactjs';
import { htmlSerializer } from '../../utils/htmlSerializer';
import AnimatedDiv from '../../components/AnimatedDiv/AnimatedDiv';

const TemplateB = ({ data }) => {
  return (
    <Wrapper>
      <Content>
        <Container>
          <AnimatedDiv>
            <h1>{data.title?.[0]?.text}</h1>
            {data.subtitle?.[0]?.text && <Quote color="black">{data.subtitle?.[0]?.text}</Quote>}
            {data?.text && <RichText render={data?.text} htmlSerializer={htmlSerializer} />}
          </AnimatedDiv>
        </Container>
        <ImageContainer>
          <div className="image-wrapper">
            {data.main_image?.url && (
              <div className="image-wrapper">
                <LazyImage alt="@TODO" height="320" width="493" src={data.main_image.url} />
              </div>
            )}
            <div className="date-wrapper">
              <time dateTime={data.year?.[0]?.text}>
                <span>{data.year?.[0]?.text}</span>
              </time>
            </div>
            {data.second_image?.url && (
              <div className="image-wrapper">
                <LazyImage alt="@TODO" height="320" width="493" src={data.second_image.url} />
              </div>
            )}
          </div>
        </ImageContainer>
      </Content>
    </Wrapper>
  );
};

export default TemplateB;
