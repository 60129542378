import React, { useRef } from 'react';
import { Wrapper, TimelineItem, ScrollIndicator } from './Timeline.styles';
import { Link } from 'react-router-dom';
import LazyImage from '../LazyImage/LazyImage';
import MouseSVG from '../../static/icons/start.svg';
import PlaySVG from '../../static/icons/play.svg';
import { utrechtService } from '../../services/utrecht-service';

const Timeline = ({ items }) => {
  const ref = useRef(null);
  const sortedItems = utrechtService.getSortedCenturies({ centuries: items });

  const handleClickBack = e => {
    const startOfTimeline = document.querySelector('#scroll');
    startOfTimeline.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  };

  const handleClickScrollIndicator = () => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  };

  return (
    <Wrapper>
      <ScrollIndicator onClick={handleClickScrollIndicator}>
        <img src={MouseSVG} alt="Scroll" />
      </ScrollIndicator>
      {sortedItems?.map((item, index) => {
        const { before_image, main_image, _meta } = item.node;

        return (
          <TimelineItem key={_meta.uid} ref={index === 0 ? ref : null}>
            <div>
              <LazyImage className="small-img" src={before_image.url} alt="@TODO" />
            </div>
            <div>
              <Link className="lazy-link" to={`/eeuw/${_meta.uid}`}>
                <LazyImage className="big-img" src={main_image.url} alt="@TODO" />
              </Link>
              <img className="play-icon" src={PlaySVG} alt="" />
            </div>
          </TimelineItem>
        );
      })}
      <TimelineItem className="small">
        <div>
          <LazyImage className="small-img" src="/shield.png" alt="Terug" />
        </div>
        <div className="back" onClick={handleClickBack}>
          <h2>TERUG</h2>
        </div>
      </TimelineItem>
    </Wrapper>
  );
};

export default Timeline;
