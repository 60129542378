import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  background: var(--color-gray-light);

  h1 {
    color: var(--color-primary);
  }
`;

export const Content = styled.div`
  --container-spacing: var(--template-padding-top) 2.4rem var(--template-padding-bottom);

  display: flex;
  flex-wrap: wrap;
  position: relative;
  max-width: var(--max-container-width);
  min-height: 100vh;
  padding: var(--container-spacing);
  margin: 0 auto;

  &::before,
  &::after {
    content: '';
    height: 11px;
    width: 100%;
    background-color: var(--color-primary);
  }

  @media (${({ theme }) => theme.respondTo.desktop}) {
    --container-spacing: var(--template-padding-top) 6.4rem var(--template-padding-bottom) var(--template-padding-left);
  }

  time {
    width: 100%;

    &.large {
      font-family: var(--font-secondary);
      text-align: center;
      line-height: 1.1;
      margin: 2.4rem 0 0;
      font-size: var(--font-size-xl);

      @media (${({ theme }) => theme.respondTo.desktop}) {
        position: relative;
        top: 5.6rem;
        margin: 0;
        font-size: 343px;
      }
    }
  }
`;

export const Container = styled.div`
  --container-spacing: 0.8rem 0.8rem 4rem;

  padding: var(--container-spacing);

  @media (${({ theme }) => theme.respondTo.desktop}) {
    width: 50%;

    h1 {
      font-size: var(--font-size-lg);
    }
  }

  img {
    margin-bottom: 3.2rem;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      margin-bottom: 5.6rem;
    }
  }
`;

export const ImageContainer = styled.div`
  --container-spacing: 0.8rem 0.8rem 8rem;

  position: relative;
  width: 100%;
  padding: var(--container-spacing);

  @media (${({ theme }) => theme.respondTo.desktop}) {
    position: sticky;
    top: 0;
    height: 100%;
    width: 50%;
  }

  blockquote {
    margin: 2.4rem 0.8rem;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      margin: 5.6rem;
    }
  }
`;
