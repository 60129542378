import { gql } from '@apollo/client';

export const CENTURY_PAGE_QUERY = gql`
  query centuryPage($century: String, $allYearsUid: [String!]) {
    allCenturys(uid: $century) {
      edges {
        node {
          ... on Century {
            century_start
            century_end
            title
            text
            main_image
            split_header_image
            before_image
            _meta {
              uid
              id
            }
            highlights {
              year {
                ... on Year {
                  title
                  year
                  _meta {
                    uid
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
    allYears(uid_in: $allYearsUid, first: 99) {
      edges {
        node {
          ... on Year {
            year
            day
            template
            title
            subtitle
            text
            main_image
            second_image
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`;
