import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  background: ${({ bg }) => (bg ? bg : 'var(--color-brown)')};
`;

export const Content = styled.div`
  --container-spacing: var(--template-padding-top) 3.2rem var(--template-padding-bottom);

  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: var(--max-container-width);
  min-height: 100vh;
  margin: 0 auto;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    --container-spacing: var(--template-padding-top) 8rem var(--template-padding-bottom);

    flex-wrap: nowrap;
  }

  @media (${({ theme }) => theme.respondTo.desktopXL}) {
    --container-spacing: var(--template-padding-top) 11.2rem var(--template-padding-bottom) 0.8rem;
  }
`;

export const Container = styled.div`
  padding: var(--container-spacing);

  @media (${({ theme }) => theme.respondTo.desktop}) {
    width: 50vw;
    min-height: 100vh;
  }

  .date-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 3.2rem;
    left: 50%;
    width: 13.5rem;
    height: 13.5rem;
    margin-bottom: 2.4rem;
    transform: translateX(-50%);
    border-radius: 50%;
    background: var(--color-primary);
    z-index: 1;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      position: relative;
      top: auto;
      left: auto;
      transform: translateX(0);
    }
  }

  time {
    position: relative;
    top: 8px;
    color: white;
    font-size: 48px;
    font-family: var(--font-secondary);
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 10.4rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    width: 50vw;
    padding: var(--template-padding-top) 6.4rem 7.2rem var(--template-padding-left);
  }

  @media (${({ theme }) => theme.respondTo.desktopXL}) {
    padding: var(--template-padding-top) 6.4rem 8.8rem var(--template-padding-left);
  }

  .image-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-bottom: 6.4rem;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      margin-bottom: 0;
    }
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  blockquote {
    margin: 2.4rem 0 0.8rem;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      p {
        padding-right: 0;
        text-align: right;
      }

      &::before,
      &::after {
        margin-right: 0;
      }
    }
  }

  time {
    display: block;
    text-align: center;
    margin-bottom: 4.8rem;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      text-align: right;
    }
  }
`;
