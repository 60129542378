import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;

  h2 {
    font-size: var(--font-size-md);
    font-family: var(--font-secondary);
    color: var(--color-primary);
  }
`;
