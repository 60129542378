import React from 'react';

import SidebarNavigation from '../components/SidebarNavigation/SidebarNavigation';
import { Wrapper } from './layout.styles';
import Century from '../pages/Century';

import { SIDEBAR_QUERY } from '../queries/SIDEBAR_QUERY';
import { useQuery } from '@apollo/client';

const Layout = ({ children, ...rest }) => {
  const { error, data } = useQuery(SIDEBAR_QUERY);

  const centuries = data?.allCenturys.edges;

  if (error) return <p>Error :(</p>;

  return (
    <Wrapper {...rest}>
      {children}
      <Century />
      <SidebarNavigation centuries={centuries} />
    </Wrapper>
  );
};

export default Layout;
