import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useStore, STORE_ACTIONS } from '../../hooks/useStore';
import { Wrapper, Content } from './ContentTemplate.styles';
import LogoSVG from '../../static/icons/logo.svg';

const ContentTemplate = ({ title, children }) => {
  const { dispatch } = useStore();

  useEffect(() => {
    dispatch({ type: STORE_ACTIONS.SET_MENU_IS_OPEN, payload: false });
    return () => {
      dispatch({ type: STORE_ACTIONS.SET_MENU_IS_OPEN, payload: false });
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Helmet>
        <title>{title} - 900 jaar Utrecht - Gekomen om te blijven</title>
        <meta name="description" content="900 jaar geschiedenis" />
      </Helmet>

      <Content>
        <img className="logo-icon" src={LogoSVG} alt="" style={{ marginBottom: '40px', width: '5rem' }} />
        <h1>{title}</h1>
        {children}
      </Content>
    </Wrapper>
  );
};

export default ContentTemplate;
