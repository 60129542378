import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  background: var(--color-primary);

  h1,
  h3,
  p,
  time {
    color: white;
  }
`;

export const Content = styled.div`
  --container-spacing: var(--template-padding-top) 3.2rem var(--template-padding-bottom);

  display: flex;
  flex-wrap: wrap;
  position: relative;
  max-width: var(--max-container-width);
  min-height: 100vh;
  margin: 0 auto;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    --container-spacing: var(--template-padding-top) 6.4rem var(--template-padding-bottom);

    flex-wrap: nowrap;
  }

  blockquote p {
    color: var(--color-text);
  }

  time {
    display: block;
    font-size: var(--font-size-sm);
    margin-bottom: var(--space-unit);
  }
`;

export const Container = styled.div`
  position: relative;
  padding: var(--container-spacing);

  @media (${({ theme }) => theme.respondTo.desktop}) {
    width: 50vw;
    min-height: 100vh;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: sticky;
    order: -1;
    top: 0;
    width: 50vw;
    height: 100vh;
    padding: 7.2rem 0 7.2rem var(--template-padding-left);
  }

  .image-wrapper {
    position: relative;
    display: flex;
    gap: var(--space-unit);
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-bottom: 6.4rem;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      max-width: 600px;
      margin-bottom: 0;
    }
  }

  .date-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(50% - 3.2rem); // Minus margin bottom
    left: 50%;
    width: 12rem;
    height: 12rem;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: var(--color-primary);
    z-index: 1;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      top: 50%;
      width: 15rem;
      height: 15rem;
    }
  }

  time {
    position: relative;
    top: 8px;
    font-size: var(--font-size-lg);
    font-family: var(--font-secondary);
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;
