import React from 'react';
import { Container, ImageContainer, Wrapper, Content } from './TemplateF.styles';
import LazyImage from '../../components/LazyImage/LazyImage';
import Quote from '../../components/Quote/Quote';
import { RichText } from 'prismic-reactjs';
import { htmlSerializer } from '../../utils/htmlSerializer';
import AnimatedDiv from '../../components/AnimatedDiv/AnimatedDiv';

const TemplateF = ({ data, bg, text }) => {
  return (
    <Wrapper bg={bg} text={text}>
      <Content>
        <ImageContainer>
          {data.year?.[0]?.text && <time dateTime={data.year?.[0]?.text}>{data.year?.[0]?.text}</time>}
          {data.main_image.url && (
            <LazyImage
              alt="@TODO"
              height={data.main_image.dimensions.height}
              width={data.main_image.dimensions.width}
              src={data.main_image.url}
            />
          )}
        </ImageContainer>
        <Container>
          <h1>{data.title?.[0]?.text}</h1>

          <AnimatedDiv>
            {data?.text && (
              <div className="content">
                <RichText render={data?.text} htmlSerializer={htmlSerializer} />
              </div>
            )}
          </AnimatedDiv>

          {data.subtitle?.[0]?.text && (
            <AnimatedDiv transition={{ ease: [0.2, 0.82, 0.165, 1], duration: 2, delay: 0.75 }}>
              <Quote color="white" border center size="large">
                {data.subtitle?.[0]?.text}
              </Quote>
            </AnimatedDiv>
          )}
        </Container>
      </Content>
    </Wrapper>
  );
};

export default TemplateF;
