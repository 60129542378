import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  background: #b2a89e;
  h1,
  h3,
  p {
    color: black;
  }
  time {
    color: var(--color-primary);
  }

  .date-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 3.2rem;

    p {
      font-size: 1.5rem;
      color: var(--color-primary);
      border-bottom: 5px solid var(--color-primary);
      transform: translateY(-3.5rem);

      @media (${({ theme }) => theme.respondTo.desktop}) {
        font-size: 2rem;
        border-bottom: 10px solid var(--color-primary);
        padding-right: 5rem;
        transform: translateY(-5.5rem);
      }
    }
  }
`;

export const Content = styled.div`
  --container-spacing: var(--template-padding-top) calc(var(--space-unit) * 4) calc(var(--space-unit) * 12);

  position: relative;
  min-height: 100vh;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    --container-spacing: 0 8rem 12.8rem var(--template-padding-left);
  }

  @media (${({ theme }) => theme.respondTo.desktopXL}) {
    --container-spacing: 0 11.2rem 12.8rem var(--template-padding-left);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  max-width: var(--max-container-width);
  padding: var(--container-spacing);
  margin: 0 auto;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    z-index: 1;
  }

  time {
    display: block;
    font-size: 84px;
    font-family: var(--font-secondary);
    line-height: 1.2;
    text-align: center;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      margin-top: 10rem;
      margin-left: auto;
      font-size: 172px;
      line-height: 1;
      z-index: 1;
    }
  }

  h1 {
    @media (${({ theme }) => theme.respondTo.desktop}) {
      font-size: 69px;
    }
  }

  .content {
    margin: 3.2rem 0;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      margin: 6.4rem 8rem;
    }
    &-small {
      margin: 3.2rem 0 0;

      @media (${({ theme }) => theme.respondTo.desktop}) {
        margin: 0 8rem;
      }
    }
  }

  blockquote {
    margin: 0;
    text-align: left;

    &:before,
    &:after {
      content: none;
    }

    p {
      color: var(--color-black);
      padding: 0;
      @media (${({ theme }) => theme.respondTo.desktop}) {
        font-size: 4rem;
      }
    }
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  margin: 0 -3.2rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding-top: 4rem;
    padding-top: 0;
    margin: 0;
  }

  img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
    aspect-ratio: 21/10;
  }
`;
