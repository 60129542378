import React from 'react';
import { Blockquote } from './Quote.styles';

const Quote = ({ color, center, border, size, children }) => {
  return (
    <Blockquote color={color} border={border} center={center} size={size}>
      <p>{children}</p>
    </Blockquote>
  );
};

export default Quote;
